import { graphql } from 'gatsby';
import React from 'react';
import { HeadWrapper } from '../../layout/HeadWrapper';
import Layout from '../../layout/index';
import { useTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { morphism } from 'morphism';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHeroVideo } from '../../morphismSchema/templates/schemaHeroVideo';
import { HeroVideo } from '@payfit/growth-platform-brand-system-v2/templates/HeroVideo';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="NewFeaturesWall"
      dataName={'datoCmsNewFeaturesWallPage'}
    />
  );
};

export const query = graphql`
  query DatoNewFeaturesWallPage($locale: String, $id: String) {
    datoCmsNewFeaturesWallPage(locale: $locale, id: { eq: $id }) {
      ...NewFeaturesWallPage
    }
    allDatoCmsNewFeaturePage(
      locale: $locale
      filter: { locales: { eq: $locale } }
      sort: { _allDateLocales: { value: DESC } }
    ) {
      nodes {
        id
        slug
        date
        doNotCreatePage
        hero {
          image {
            url
            alt
            width
            height
          }
          filterTags {
            title
            tagType
          }
        }
        metaFields {
          description
          title
          image {
            url
          }
        }
      }
    }
  }
`;

const NewFeaturesWall = ({ data, pageContext }: any) => {
  const { trackingPage } = useTracking();
  const {
    functionalityLabel,
    userFilter,
    userLabel,
    seeMoreButtonLabel,
    noResultText,
    ...doc
  }: any = data.datoCmsNewFeaturesWallPage;

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });
  const hero = _data?.hero;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="NewFeaturesWall">
      {hero && (
        <HeroVideo {...morphism(schemaHeroVideo, { ...hero, trackingPage })} />
      )}
      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone
          {..._data}
          doc={{
            ...doc,
            allDatoCmsNewFeaturePage: data?.allDatoCmsNewFeaturePage,
          }}
        />
      )}
    </Layout>
  );
};

export default NewFeaturesWall;
